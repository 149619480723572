<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'TaskList' }">{{
        $t("buttons.task_center")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item
        >{{ $t("taskcenter.task") }} # {{ task_id }}
        <span v-if="user_name === admin_name">
          ({{ $t("dashboard.home.user") }} {{ user_dict[task.owner_id] }})</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>
            {{ $t("taskcenter.task") }} # {{ task_id }} （
            <span v-if="lang_status == 'en'"> {{ task_status }}</span>
            <span v-else>{{ task_status | task_status_zhcn }}</span>
            ）
          </p>
        </div>
      </div>

      <div class="page-block-content">
        <div>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleDownloadResult"
            :disabled="task.status !== 'finished'"
            >{{ $t("taskcenter.downaload") }}</el-button
          >
          <el-button
            type=""
            icon="el-icon-copy-document"
            @click="handleCopyTask"
            :disabled="task.status !== 'finished'"
            >{{ $t("taskcenter.copy_settings") }}</el-button
          >
        </div>
        <div class="results-part">
          {{ $t("dashboard.home.disk_space_usage") }}:
          {{ (task.space_used * 1e-9).toPrecision(3) }} GB
        </div>
      </div>
    </div>

    <div class="page-content-fill" v-loading="content_in_processing">
      <el-tabs v-model="active_tab_name" @tab-click="handleClickTab">
        <el-tab-pane :label="$t('taskcenter.export')" name="console">
          <div v-if="logs.length > 0">
            <div v-for="(log, i) in logs" :key="i" class="output_code">
              {{ log }}
            </div>
          </div>
          <div v-else>
            {{ $t("taskcenter.current_task_status") }}
            <span v-if="lang_status == 'en'"> {{ task_status }}</span>
            <span v-else>{{ task_status | task_status_zhcn }}</span>
            {{ $t("taskcenter.status_output_null") }}
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('taskcenter.taskviewer.show_results')"
          name="viz"
          :disabled="visable === false"
        >
          <el-row v-if="visable">
            <el-col :span="24"
              ><div class="grid-content bg-purple">
                {{ $t("taskcenter.dataset") }}
                <el-select
                  style="width: 100%"
                  v-model="viz_data_name_selected"
                  :placeholder="$t('taskcenter.dataset_select')"
                  @change="getTaskVizWithName(viz_data_name_selected)"
                >
                  <el-option
                    v-for="(item, i) in viz_dataset"
                    :key="i"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select></div
            ></el-col>
          </el-row>
          <el-row
            v-if="viz_data_name_selected.length > 0 && plot_available === true"
          >
            <el-col :span="24"
              ><div class="grid-content bg-purple">
                {{ $t("taskcenter.datalabel") }}
                <el-select
                  style="width: 100%"
                  v-model="viz_label_selected"
                  :placeholder="$t('taskcenter.label_select_plot')"
                  @change="fillData(viz_label_selected)"
                >
                  <el-option
                    v-for="(item, i) in labels_to_draw"
                    :key="i"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select></div
            ></el-col>
          </el-row>
          <el-row
            class="chart-area"
            v-if="viz_data_name_selected.length > 0 && plot_available === true"
          >
            <el-col :span="24"
              ><el-card shadow="never">
                <line-chart
                  :chart-data="data2show"
                  :styles="viz_styles"
                ></line-chart>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import { mapGetters, mapState, mapActions } from "vuex";
import LineChart from "./LineChart.js";
export default {
  name: "TaskViewer",
  components: {
    LineChart,
  },
  data() {
    return {
      logs: [],
      active_tab_name: "console",
      task: {
        status: null,
        owner_id: null,
        space_used: null,
      },
      data2show: {},
      visable: false,
      plot_available: false,
      viz_data: {
        x: [],
        y: {},
      },
      viz_dataset: [],
      viz_data_name_selected: "",
      viz_label_selected: "",
      viz_height: 40,

      console_log_tailer: null,
      status_notifer: null,
    };
  },

  beforeMount() {
    this.switchDefaultPage("/dashboard/task-center");
  },
  computed: {
    ...mapState({
      user_statistics: (state) => state.user.statistics,
      lang_status: (state) => state.lang_status,
      user_name: (state) => state.user.statistics.name,
      admin_name: (state) => state.admin_name,
      app_name: (state) => state.app_name,
    }),
    ...mapGetters(["headers4reqs", "user_dict", "access_token"]),
    task_id() {
      return this.$route.params.id;
    },
    labels_to_draw() {
      return Object.keys(this.viz_data.y);
    },
    viz_styles() {
      return {
        height: `${this.viz_height}vh`,
        position: "relative",
      };
    },

    task_status() {
      return this.task.status;
    },
  },
  watch: {
    task_status(nv) {
      if (nv === "finished") {
        this.getTaskDataset();
      }
      return nv;
    },
  },
  async mounted() {
    await this.getTaskDetails();
    await this.initLogTailer();
    await this.initStatusNotifier();
  },
  destroyed() {
    this.removeLogTailer();
    this.removeStatusNotifier();
  },
  methods: {
    ...mapActions(["switchDefaultPage"]),
    handleClickTab() {},
    async initLogTailer() {
      if (this.console_log_tailer === null && this.task_id !== undefined) {
        console.log("initLogTailer");
        let vm = this;
        const url =
          process.env.VUE_APP_WS_API_BASE_URL +
          apiURL.ws_stage +
          apiURL.ws.log_tailer.task.replace("task_id", vm.task_id);

        console.log("启动log tailer URL:", url);
        vm.console_log_tailer = new WebSocket(url);
        vm.console_log_tailer.onopen = () => {
          const auth_info = {
            user_id: vm.user_statistics.id,
            access_token: vm.access_token,
          };
          if (vm.console_log_tailer !== null) {
            vm.console_log_tailer.send(JSON.stringify(auth_info));
          }
        };

        this.console_log_tailer.onmessage = (event) => {
          const content = event.data;
          vm.logs.push(content);
          if (vm.console_log_tailer !== null) {
            vm.console_log_tailer.send("pong");
          }
        };
        this.console_log_tailer.onclose = () => {
          console.log("Log tailer closed.");
          setTimeout(() => {
            vm.initLogTailer();
          }, vm.reconnect_interval);
        };
        return true;
      } else {
        return false;
      }
    },
    async removeLogTailer() {
      this.console_log_tailer = null;
    },

    async initStatusNotifier() {
      if (this.status_notifer === null && this.task_id !== undefined) {
        let vm = this;
        const url =
          process.env.VUE_APP_WS_API_BASE_URL +
          apiURL.ws_stage +
          apiURL.ws.log_tailer.task_status.replace("task_id", vm.task_id);

        console.log("启动状态监听 URL:", url);
        vm.status_notifer = new WebSocket(url);
        vm.status_notifer.onopen = () => {
          const auth_info = {
            user_id: vm.user_statistics.id,
            access_token: vm.access_token,
          };
          if (vm.status_notifer !== null) {
            vm.status_notifer.send(JSON.stringify(auth_info));
          }
        };

        this.status_notifer.onmessage = async (event) => {
          const content = event.data;
          console.log("状态更新到 ", content);
          if (vm.status_notifer !== null) {
            vm.status_notifer.send("pong");
          }

          // 每当出现状态更新时，重新获取task细节数据
          // 由于状态更新不会非常快，这里可以使用http请求
          await vm.getTaskDetails();
          // 同时重新获取console.log
          await vm.removeLogTailer();
          await vm.initLogTailer();
        };
        return true;
      } else {
        return false;
      }
    },
    removeStatusNotifier() {
      this.status_notifer = null;
    },

    fillData(label) {
      this.data2show = {
        labels: this.viz_data.x,
        datasets: [
          {
            label: label,
            backgroundColor: "#f87979",
            data: this.viz_data.y[label],
          },
        ],
      };
    },
    async getTaskDetails() {
      if (this.task_id !== undefined) {
        this.$get_api(
          apiURL.task.details.replace("task_id", this.task_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.task = res;
          return res.status;
        });
      }
    },
    async getTaskDataset() {
      if (this.task_id !== undefined) {
        this.$get_api(
          apiURL.task.visualization_dataset.replace("task_id", this.task_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.viz_dataset = res;
          this.visable = true;
        });
      }
    },
    async getTaskVizWithName(data_name) {
      if (this.task_id !== undefined) {
        this.$get_api(
          apiURL.task.visualization_data
            .replace("task_id", this.task_id)
            .replace("data_name", data_name),
          null,
          this.headers4reqs
        )
          .then((res) => {
            this.plot_available = true;
            this.viz_data = res;
            this.visable = true;
            return res.status;
          })
          .catch((e) => {
            this.visable = true;
            this.plot_available = false;
            this.$message.error(this.$t("taskcenter.null_data") + " " + e);
            return false;
          });
      }
    },

    handleDownloadResult() {
      this.$get_download_blob_api(
        apiURL.task.download.replace("task_id", this.task_id),
        null,
        this.headers4reqs
      ).then((res) => {
        var filename = `results-${this.task_id}.zip`;
        var blob = new Blob([res], { type: "application/zip" });
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    handleCopyTask() {
      this.$router.push({
        name: "TaskCreate",
        query: { type_create: "copy", task_id_copied: this.task_id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/main";

$margin-block: 0.2rem;
.output_code {
  background: rgb(49, 49, 49);
  color: white;
  padding: $margin-block * 2;
  &:first-of-type {
    border-top-left-radius: $margin-block;
    border-top-right-radius: $margin-block;
  }
  &:last-of-type {
    border-bottom-left-radius: $margin-block;
    border-bottom-right-radius: $margin-block;
  }
  font-family: monospace;
}
.chart-area {
  margin-top: $site-area-space;
}
</style>
